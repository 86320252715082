<template>
    <div class="business-page">
        <div class="business-page__header">
            <div class="business-page__top"></div>
            <div class="business-page__header-wrap">
                <div>
                    <img src="@/assets/images/business/B2B.png" alt="">
                </div>
                <div>
                    <h2>Sveiko ir subalansuoto maisto indelių 
ir maisto rinkinių pristatymo pasiūlymas</h2>
                </div>
            </div>
            <div class="business-page__bottom"></div>
        </div>
        

        <div class="business-page__contact">
            <div class="business-page__green-block business-page__green-block--right">
                <img src="@/assets/images/business/contact_fridge.png" alt="">
            </div>
            <div class="business-page__contact-wrap business-page__contact-wrap--right">
                <GetInTouchSection businessForm full></GetInTouchSection>
            </div>
        </div>

        <div class="business-page__slider">
            <Slider
                :slides-per-view="slideCount"
                :space-between="30"
                >
                    <SwiperSlide>
                        <div class="business-page__slider-item" @click.prevent="slideTosection(1)">
                            <img class="cristmas-page__image" :src="require(`@/assets/images/business/slider_1.png`)" alt="">
                            <div class="business-page__slider-item-content">
                                <h3>Komandinis rinkinių užsakymas</h3>
                                <div>
                                    <div>Nuo</div>
                                    <div class="bold">€12.35</div>
                                    <div>Dienai</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="business-page__slider-item" @click.prevent="slideTosection(2)">
                            <img class="cristmas-page__image" :src="require(`@/assets/images/business/slider_2.png`)" alt="">
                            <div class="business-page__slider-item-content">
                                <h3>Rinkinių užsakymas įmonei</h3>
                                <div>
                                    <div>Nuo</div>
                                    <div class="bold">€9.14 be PVM</div>
                                    <div>Dienai</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="business-page__slider-item" @click.prevent="slideTosection(3)">
                            <img class="cristmas-page__image" :src="require(`@/assets/images/business/slider_3.png`)" alt="">
                            <div class="business-page__slider-item-content">
                                <h3>Firminis Cityrush šaldytuvas</h3>
                                <div>
                                    <div>Nuo</div>
                                    <div class="bold">€6.92 be PVM</div>
                                    <div>Dienai</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Slider>
        </div>

        <div class="business-page__howitworks">
            <HowItWorksSection horizontal/>
        </div>

        <div class="business-page__with-image" id="section-1">
            <h2>Komandinis rinkinių užsakymas</h2>
            <div class="business-page__with-image-wrap">
                <div class="business-page__with-image-item business-page__with-image-item--image">
                    <img src="@/assets/images/business/team_order_City_rush_Mar2024-68.png" alt="">
                </div>
                <div class="business-page__with-image-item business-page__with-image-item--content">
                    <h3>Dirbate vienoje darbovėtėje?</h3>
                    <p>
                        Esate kolegos? Atsibodo valgyti greito maisto 
užkandinėse, o kavinių <span class="bold">maistas nebedžiugina?</span>
Norite užsisakyti didesnį kiekį rinkinių? 
Kviečiame susipažinti su specialiu pasiūlymu 
komandiškam rinkinių užsakymui!
                    </p>
                    <CRButton @click="contact()">Susisiekite</CRButton>

                </div>
            </div>
        </div>

        <div class="business-page__content">
            
            <div>Pasirinkite kuriuos norite rinkinius iš mūsų siūlomų rinkinių: <span class="bgreen">mitybos rinkiniai</span></div>
            <div>Minimalus užsakymo kiekis: <span class="bgreen">5 rinkiniai</span></div>
            <div>Pristatysime nurodytu adresu <span class="bgreen">NEMOKAMAI</span></div>
        

            <div class="space bold">Taikomos komandinio užsakymo nuolaidos:</div>

            <div>Užsakant 5 rinkinius, taikome <span class="bgreen">-5% nuolaidą</span></div>
            <div>Užsakant nuo 6 iki 10 rinkinių, taikome <span class="bgreen">-7% nuolaidą</span></div>
            <div>Užsakant nuo 10 ir daugiau rinkinių, taikome <span class="bgreen">-9% nuolaidą</span></div>
            <div>Užsakant nuo 20 rinkinių, taikome <span class="bgreen">-11% nuolaidą ir daugiau</span></div>
        </div>

        <div class="business-page__with-image" id="section-2">
            <h2>Rinkinių užsakymas įmonei</h2>
            <div class="business-page__with-image-wrap">
                <div class="business-page__with-image-item business-page__with-image-item--image">
                    <img src="@/assets/images/business/lunch-02.png" alt="">
                </div>
                <div class="business-page__with-image-item business-page__with-image-item--content">
                    <h3>Svarstote apie papildomas naudas
darbuotojui? </h3>
                    <p>
                        Darbuotojam <span class="bold">trūksta motyvacijos?</span> Nežinote kaip padidinti
<span class="bold">darbingumo lygį?</span> Siūlome užsisakyti visos įmonės 
darbuotojams Cityrush mitybos rinkinius, skatinti darbuotojus
sveikai maitintis, <span class="bold">didinti jų produktyvumą</span>. Tai puiki galimybė
pakeisti darbuotojų rutiną. Galima pasirinkti iš visų mūsų 
<span class="bold">siūlomų mitybos</span> rinkinių atsižvelgiant į darbuotojų skirtingus 
maisto poreikius, alergijas ir skonį.

                    </p>
                    <CRButton @click="contact()">Susisiekite</CRButton>
                </div>
            </div>
        </div>

        <div class="business-page__content">
            <div>Pasirinkite kuriuos norite rinkinius iš mūsų siūlomų rinkinių: <span class="bgreen">mitybos rinkiniai</span></div>
            <div>Minimalus užsakymo kiekis: <span class="bgreen">5 rinkiniai</span></div>
            <div>Pristatysime nurodytu adresu  <span class="bgreen">NEMOKAMAI</span></div>
        </div>

        <div class="business-page__table-content">
            <h3>Darbui+ rinkinio kaina nuo 9,14 Eur už dieną:</h3>
            <p>Galite lanksčiai pasirinkti iš pusryčių, pietų, vakarienės ar užkandžių kombinacijų. Daugiau apie rinkinį: Darbui+ 
Šį Cityrush rinkinį sudaro 2 jūsų pasirinkti patiekalai.</p>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>1000 kcal rinkinys Subalansuotas</th>
                            <th>Standartinė 20 d.d. kaina</th>
                            <th>Taikoma nuolaida</th>
                            <th>Galutinė kaina su nuolaida ir nemokamu pristatymu <span class="borange">be PVM</span></th>
                            <th>Vieno rinkinio kaina</th>
                            <th>Kaina už dieną</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>5 rinkiniai</td>
                            <td>2 500 Eur</td>
                            <td>5 %</td>
                            <td>1 395,93 Eur</td>
                            <td>195,13 Eur</td>
                            <td class="bgreen">9,76 Eur</td>
                        </tr>
                        <tr>
                            <td>8 rinkiniai</td>
                            <td>4 000 Eur</td>
                            <td>7 %</td>
                            <td>2 186,47 Eur</td>
                            <td>191,02 Eur</td>
                            <td class="bgreen">9,55 Eur</td>
                        </tr>
                        <tr>
                            <td>10+ rinkinių</td>
                            <td>5 000 Eur</td>
                            <td>9 %</td>
                            <td>2 674,31 Eur</td>
                            <td>186,91 Eur</td>
                            <td class="bgreen">9,35 Eur</td>
                        </tr>
                        <tr>
                            <td>20+ rinkinių</td>
                            <td>10 000 Eur</td>
                            <td>nuo 11 %</td>
                            <td>5 231,07 Eur</td>
                            <td>182,81 Eur</td>
                            <td class="bgreen">9,14 Eur</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="business-page__table-content">
            <h3>Subalansuoto rinkinio kaina nuo 11,92 Eur už dieną:</h3>
            <p>Galite psirinkti jums tinkamiausią maisto rinkino kalorijų normą – 1000, 1500 ir 2000 kcal. 
Šį Cityrush rinkinį sudaro 3 patiekalai: pusryčiai, pietūs ir vakarienė. Daugiau apie rinkinį: Subalansuotas </p>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>1000 kcal rinkinys Subalansuotas</th>
                            <th>Standartinė 20 d.d. kaina</th>
                            <th>Taikoma nuolaida</th>
                            <th>Galutinė kaina su nuolaida ir nemokamu pristatymu <span class="borange">be PVM</span></th>
                            <th>Vieno rinkinio kaina</th>
                            <th>Kaina už dieną</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>5 rinkiniai</td>
                            <td>2 500 Eur</td>
                            <td>5 %</td>
                            <td>1 395,93 Eur</td>
                            <td>195,13 Eur</td>
                            <td class="bgreen">9,76 Eur</td>
                        </tr>
                        <tr>
                            <td>8 rinkiniai</td>
                            <td>4 000 Eur</td>
                            <td>7 %</td>
                            <td>2 186,47 Eur</td>
                            <td>191,02 Eur</td>
                            <td class="bgreen">9,55 Eur</td>
                        </tr>
                        <tr>
                            <td>10+ rinkinių</td>
                            <td>5 000 Eur</td>
                            <td>9 %</td>
                            <td>2 674,31 Eur</td>
                            <td>186,91 Eur</td>
                            <td class="bgreen">9,35 Eur</td>
                        </tr>
                        <tr>
                            <td>20+ rinkinių</td>
                            <td>10 000 Eur</td>
                            <td>nuo 11 %</td>
                            <td>5 231,07 Eur</td>
                            <td>182,81 Eur</td>
                            <td class="bgreen">9,14 Eur</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="business-page__with-image" id="section-3">
            <h2>Firminis Cityrush šaldytuvas</h2>
            <div class="business-page__with-image-wrap">
                <div class="business-page__with-image-item business-page__with-image-item--image">
                    <img src="@/assets/images/business/ofisas-01.png" alt="">
                </div>
                <div class="business-page__with-image-item business-page__with-image-item--content">
                    <h3>Maisto šaldytuvas ofisui? Taip!</h3>
                    <p>
                        Pristatysime į jūsų ofisą vardinį Cityrush šaltyduvą, mylėsime 
jį, prižiūrėsime ir kasdien sutartų valandų intervale 
papildysime Cityrush patiekalais bei užkandžiais. 
Nesuvalgytą likutį išvešime arba paliksime jums.
</p>
                        <p>Vienas šaldytuvas talpina apie 50 vienetų pagrindinių patiekalų.
Yra galimybė pristatyti mažai komandai, mažo litražo šaldytuvą.</p>
                    <CRButton @click="contact()">Susisiekite</CRButton>
                </div>
            </div>
        </div>

        <div class="business-page__content">
            <div>Šaldytuvo kaina:  <span class="deleted">1000 Eur</span> <span class="bgreen">NEMOKAMAI</span></div>
            <div>Pilno maisto šaldytuvo kaina komandai: <span class="deleted">245 Eur</span>  nuo <span class="bgreen">215,30 Eur</span></div>
            <div>Pristatysime nurodytu adresu <span class="bgreen">NEMOKAMAI</span></div>
            <div>Taikoma nuolaida užsakymui: <span class="bgreen">iki -30%</span></div>
            <div>Šaldytuvo taisymo ir priežiūros darbai  <span class="NEMOKAMAI"></span></div>
        </div>

        <div class="business-page__table-content">
            <h3>20 žmonių komandai pasiūlymas:</h3>
            <div class="row">
                <div class="column">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Patiekalas</th>
                                    <th>Kaina</th>
                                    <th>Vienetai</th>
                                    <th>Šaldytuvo patiekalų kaina be PVM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Pagrindinis patiekalas (Mažo dydžio, 350 kcal)</td>
                                    <td>5,85 Eur</td>
                                    <td>18 vnt.</td>
                                    <td>105,30 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (Vidutinio dydžio, 500 kcal)</td>
                                    <td>6,30 Eur</td>
                                    <td>18 vnt.</td>
                                    <td>113,40 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (Didelio dydžio, 650 kcal)</td>
                                    <td>7,10 Eur</td>
                                    <td>18 vnt.</td>
                                    <td>127,80 Eur</td>
                                </tr>
                                <tr>
                                    <td>Dienos užkandis + dienos sriuba</td>
                                    <td>2,50 + 3,00 Eur</td>
                                    <td>10 + 15 vnt.</td>
                                    <td>25 + 30 Eur</td>
                                </tr>
                                <tr>
                                    <td>Dienos daržovių indelis + dienos glotnutis</td>
                                    <td>3,00 + 2,50 Eur</td>
                                    <td>10 + 15 vnt.</td>
                                    <td>30 + 25 Eur</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <th>Pasiūlymai</th>
                                <th>Dienos kaina asmeniui be PVM</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Pagrindinis patiekalas
    (350 kcal) + užkandis +
    sriuba + daržovės + 
    glotnutis</td>
                                    <td>10,77 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas
    (500 kcal) + užkandis +
    sriuba + daržovės + 
    glotnutis</td>
                                    <td>11,17 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas
    (650 kcal) + užkandis +
    sriuba + daržovės + 
    glotnutis</td>
                                    <td>11,89 Eur</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="business-page__table-content">
            <h3>50 žmonių komandai pasiūlymas:</h3>
            <div class="row">
                <div class="column">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Patiekalas</th>
                                    <th>Kaina</th>
                                    <th>Vienetai</th>
                                    <th>Šaldytuvo patiekalų kaina be PVM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Pagrindinis patiekalas(Mažo dydžio, 350 kcal)</td>
                                    <td>5,85 Eur</td>
                                    <td>45 vnt.</td>
                                    <td>263,25 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (Vidutinio dydžio, 500 kcal)</td>
                                    <td>6,30 Eur</td>
                                    <td>45 vnt.</td>
                                    <td>283,50 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (Didelio dydžio, 650 kcal)</td>
                                    <td>7,10 Eur</td>
                                    <td>45 vnt.</td>
                                    <td>319,50 Eur</td>
                                </tr>
                                <tr>
                                    <td>Dienos užkandis + dienos sriuba</td>
                                    <td>2,50 + 3,00 Eur</td>
                                    <td>10 + 15 vnt.</td>
                                    <td>25 + 30 Eur</td>
                                </tr>
                                <tr>
                                    <td>Dienos daržovių indelis + dienos glotnutis</td>
                                    <td>3,00 + 2,50 Eur</td>
                                    <td>10 + 15 vnt.</td>
                                    <td>30 + 25 Eur</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <th>Pasiūlymai</th>
                                <th>Dienos kaina asmeniui be PVM</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Pagrindinis patiekalas (350 kcal) + užkandis + sriuba + daržovės + glotnutis</td>
                                    <td>7,47 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (500 kcal) + užkandis + sriuba + daržovės + glotnutis</td>
                                    <td>7,87 Eur</td>
                                </tr>
                                <tr>
                                    <td>Pagrindinis patiekalas (650 kcal) + užkandis + sriuba + daržovės + glotnutis</td>
                                    <td>8,59 Eur</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="business-page__divider">
            <img src="@/assets/images/business/divider.png" alt="">
        </div>

        <div class="business-page__payment">
            <h2>Atsiskaitymo tvarka</h2>
            <div class="business-page__payment-wrap">
                <div class="business-page__payment-item">
                    <img src="@/assets/images/business/warning.png" alt="">
                    <p>
                        Pasirašius maisto tiekimo sutartį, už pristatytus maisto patieklaus į šaldytuvą ar maisto rinkinius, mokėjimą 
    prašysime atlikti kas savaitę. Susitarus, kas dvi savaites.
                    </p>
                </div>
                <div class="business-page__payment-item">
                    <img src="@/assets/images/business/warning.png" alt="">
                    <p>
                        Kiekvienos sekančios savaitės patiekalų sudėtį ir kiekį galime koreguoti remiantis einamosios svaitės patiekalų 
    suvartojimo rezultatais. Populiarių patiekalų kiekį didinsime, nepopuliarių atitinkamai mažinsime.
                    </p>
                </div>
                <div class="business-page__payment-item">
                    <img src="@/assets/images/business/warning.png" alt="">
                    <p>
                        Norėdami pristatyti Jums Cityrush šaltyduvą prašysime pasirašyti 20 darbo dienų tiekimo sutartį.
                    </p>
                </div>
            </div>
        </div>

        <div class="business-page__footer">
            <div class="business-page__footer-wrap">
                <div class="business-page__footer-item">
                    <div>
                        <div class="business-page__footer-item-image">
                            <img src="@/assets/images/business/icons/1.png" alt="">
                        </div>
                        <p>Pristatyta daugiau nei 1300000 rinkinių</p>
                    </div>
                </div>
                <div class="business-page__footer-item">
                   <div>
                        <div class="business-page__footer-item-image">
                            <img src="@/assets/images/business/icons/2.png" alt="">
                        </div>
                        <p>Nuvažiuota daugiau nei 900000 kilometrų <span>(arba daugiau nei 22 kartais aplink Žemę)</span></p>
                   </div>
                </div>
                <div class="business-page__footer-item">
                    <div>
                        <div class="business-page__footer-item-image">
                            <img src="@/assets/images/business/icons/3.png" alt="">
                        </div>
                        <p>Kiekvieną dieną išskirtiniai šefo kuriami patiekalai</p>
                    </div>
                </div>
                <div class="business-page__footer-item">
                    <div>
                        <div class="business-page__footer-item-image">
                            <img src="@/assets/images/business/icons/4.png" alt="">
                        </div>
                        <p>Daugiau nei 90000 teigiamų atsiliepimų</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="business-page__contact" id="contact-form">
            <div class="business-page__contact-wrap business-page__contact-wrap--left">
                <GetInTouchSection businessForm full></GetInTouchSection>
            </div>
            <div class="business-page__green-block business-page__green-block--left">
                <img src="@/assets/images/business/contact_fridge.png" alt="">
            </div>
        </div>      

       
    </div>
</template>
<script>
import GetInTouchSection from '@/components/MainSections/GetInTouchSection.vue';
import HowItWorksSection from '@/components/MainSections/HowItWorksSection.vue';
import Slider from '@/components/Common/Slider/Slider.vue';
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import { SwiperSlide } from 'swiper/vue/swiper-slide';
export default {
    components: {
        GetInTouchSection,
        HowItWorksSection,
        Slider,
        SwiperSlide,
        CRButton,
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        slideCount() {
            if(window.innerWidth <= 900) {
                return 1;
            } else {
                return 3;
            }
        }
    },      
    methods: {
        slideTosection(id) {
            let yOffset = -120;
            const el = document.querySelector(`#section-${id}`);
            const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        },  
        contact() {
            // scroll to contact page
            document.getElementById('contact-form').scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    
    $spaces: 50px;

    .bgreen {
        font-weight: 700;
        color: #00a755;
    }

    .borange {
        font-weight: 700;
        color: #f67c00;
    }

    .rows {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        padding: 30px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .column {
        display: flex;
        flex-direction: column;
        flex-basis: 40%;
        flex-grow: 1;

        @media screen and (max-width: 900px) {
            flex-basis: 100%;
        }
    }

    .row {
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 30px;
        @media screen and (max-width: 900px) {
            flex-wrap: wrap;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: solid 1px black;
        border-style: hidden;
        border-radius: 20px;
        box-shadow: 0 0 0 1px #666;
        @media screen and (max-width: 900px) {
            margin-top: 30px;
            width: 100%;
        }
        

        td, th {
            border: solid 1px black;
            padding: 10px;
            &:first-child {
                border-left: none;
            }
            &:last-child {
                border-right: none;
            }
        }
    }

    .table-responsive {
        overflow-x:auto;
        width: 100%;
        padding: 5px;
    }

    .business-page {
        max-width: 1800px;
        margin: 0 auto;

        &__divider {
            margin-top: $spaces;
        }

        &__table-content {
            margin-top: 30px;
            padding: 0 15px 0 15px;
            h3 {
                font-weight: bold;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: $spaces - 20;
            }


        }


        &__with-image {
            margin-top: $spaces;
            margin-bottom: $spaces;
            padding: 0px 15px 0px 15px;
        }

        &__with-image-wrap {
            display: flex;
            gap: 30px;
            @media screen and (max-width: 900px) {
                flex-wrap: wrap;
            }
        }

        &__with-image-item {
            flex-basis: 40%;
            @media screen and (max-width: 900px) {
                flex-basis: 100%;
            }

            &--image {
                flex-basis: 50%;
                @media screen and (max-width: 900px) {
                    flex-basis: 100%;
                }
            }   

            .bold {
                font-weight: 600;
            }

            h3 {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 20px;
            }
        }

        &__content {
            padding: 0px 15px 0px 15px;

            p {
                margin-top: 30px;
            }

            .space {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .bold {
                font-weight: 700;
            }

            .deleted {
                background: linear-gradient(to left top, transparent 47.75%, red 49.5%, red 50.5%, transparent 52.25%);
            }
        }

        &__header {
            position: relative;
            z-index: 0;
            overflow: hidden;
            text-align: center;
            // background: #01a858;
        }

        &__header-wrap {
            margin: 0 auto;
            color: black;
            min-height: 200px;
            display: flex;
            align-items: center;
            gap: 30px;
            justify-content: center;
            padding: 20px;


            @media screen and (max-width: 500px) {
                flex-wrap: wrap;

                img {
                    width: 100%;
                }

                h2 {
                    text-align: center !important;
                }
            }

            div {
                width: 100%;
            }

            h2 {
                text-align: left;
                font-weight: 600;
            }
            
            img {
                width: 400px;
            }
        }

        &__top {
            background-image: url('../../assets/images/business/bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 300px;
        }

        &__bottom {
            background-image: url('../../assets/images/business/bg2.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 300px;
        }


        &__green-block {
            background: #e5f6ee;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;

            &--left {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }
            
            &--right {
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
            }
        }

        &__contact {
            display: flex;
            gap: 50px;
            margin-top: 30px;
            @media screen and (max-width: 900px) {
                flex-wrap: wrap;
            }
            
            div {
                width: 100%;
            }
        }

        &__contact-wrap {
            &--left {
                padding-left: 15px;
                @media screen and (max-width: 900px) {
                    padding-right: 15px;
                }
            }

            &--right {
                padding-right: 15px;
                @media screen and (max-width: 900px) {
                    padding-left: 15px;
                }
            }
        }

        &__howitworks {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: $spaces;
            margin-top: $spaces;
        }

        &__slider {
            margin-top: $spaces;
            margin-bottom: $spaces;
            padding: 0px 15px 0px 15px;
        }

        &__slider-item {
            border: solid 1px black;
            border-radius: 15px;
            transition: all 0.2s linear;
            transform: scale(0.95);
            cursor: pointer;

            &:hover {
                transform: scale(1);
            }

            img {
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
            
            .bold {
                font-weight: 500;
            }
        }

        &__slider-item-content {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            min-height: 120px;
            align-items: center;
        }

        &__payment {
            padding: 15px;
            margin-top: $spaces;
            margin-bottom: $spaces;
        }

        &__payment-wrap {
            display: flex;
            align-items: start;
            flex-direction: column;
            gap: 20px;
        }

        &__payment-item {
            display: flex;
            align-items: center;
            gap: 20px;

            img {
                width: 32px;
            }
        }
        
        h2 {
            margin-bottom: 20px;
        }

        
        &__footer {
            background: #E5F6EE;
            padding: 50px;
            margin-top: $spaces;
            margin-bottom: $spaces;
        }

        &__footer-wrap {
            display: flex;
            align-items: center;
            gap: 15px;
            max-width: 1000px;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__footer-item {
            background: #01a858;
            padding: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 230px;

            img {
                width: 70px;
            }

            p {
                text-align: center;
                color: white;
                font-size: 14px;

                span {
                    font-size: 12px;
                }
            }
        }

        &__footer-item-image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }


        &__list {
            list-style-type: decimal;
            padding-left: 15px;
        }

        &__image {
            border-radius: 10px;
        }

        .title__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 10px;
        }
    }
</style>